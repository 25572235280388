
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        






















$s: 1.6rem;

.checkbox,
[class*='checkbox--'] {
  position: relative;
  display: flex;
}

[class*='checkbox--'][class*='--no-label'] {
  width: $s;
  height: $s;
}

input {
  @extend %visually-hidden;

  border: 0;

  &:checked + label::after {
    display: block;
  }
  &:checked + .text::after {
    display: block;
  }
}

.text {
  padding: 0 0 0 2.5rem;
}

.label {
  padding: 0 0 0 2.5rem;
  color: $c-black;
  line-height: 1.2;

  &::before {
    content: '';
    position: absolute;
    top: 0rem;
    left: 0;
    width: $s;
    height: $s;
    border: 0.1rem solid $c-gray;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0.3rem;
    left: 0.3rem;
    display: none;
    width: 1rem;
    height: 1rem;
    background: url('../../assets/images/icons/check.svg') no-repeat center;
  }
}
