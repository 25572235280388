
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        








.tip-panel {
  // prettier-ignore
  @include fluid(padding-left, (xxs: 40px, xxl: 120px));
  // prettier-ignore
  @include fluid(padding-right, (xxs: 40px, xxl: 120px));
  // prettier-ignore
  // @include fluid(padding-top, (xxs: 30px, xxl: 80px));
  // prettier-ignore
  @include fluid(padding-bottom, (xxs: 30px, xxl: 80px));
}
