
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        


























































































































































































.align__text {
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.align__checkbox {
  display: ruby;
  justify-content: space-between;
  align-content: center;
  cursor: pointer;

  ::v-deep input[type='checkbox'] + label {
    font-size: 18px;
    color: $c-gray-darker;
  }
}

.checkbox-cases {
  display: ruby;
  justify-content: space-between;
  align-content: center;
  cursor: pointer;

  ::v-deep input[type='checkbox'] + label {
    font-size: 18px;
    color: $c-main-primary;
  }

  ::v-deep ul {
    color: $c-gray-darker;
    @media screen and (max-width: 720px) {
      padding-left: 1.5rem;
    }
  }
}

.captcha-label {
  font-size: 15px;
}

h4.fatline div.h4.fatline {
  margin-top: 10px;
}

.h4 {
  font-family: $ff-alt;
}

.form__gdpr {
  font-size: 18px;
  ::v-deep .action__label {
    text-decoration: underline;
  }
}

.header {
  font-size: 70px;
}

.inputs-inner {
  margin-right: 40px;

  ::v-deep .headline {
    font-size: 18px;
  }

  ::v-deep .input__label {
    margin-bottom: 10px;
  }
}
.form-error {
  position: relative;
  display: block;
  line-height: 1;
  bottom: 10px;
}
